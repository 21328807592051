.achievement-notification{
    position: fixed;
    width:100vw;
    height: 100vh;
    top:0;
    left:0;
    z-index:99999;

    -webkit-animation: reveal-award 0.4s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
	animation: reveal-award 0.4s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;

    .back-link{
        color:$white;
        background-color: $secondary-accent;
        border-radius: 50%;
        width:30px;
        height:30px;
        min-width: 30px;
        min-height: 30px;
        display:inline-block;
        line-height: 30px;
        text-align: center;
        font-size:1rem;
        margin-right:.6rem;
        position: absolute;
        top:20px;
        left:20px;

    }
    .achievement-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color:$white;
        text-align: center;
        margin:10% auto;
        max-width: 400px;
        padding:40px;
        h1{ font-size:1.75rem;}
        h2{ font-size:1.125rem;
        }
        h3{ font-size:0.875rem;}
        .achievement{
            width:195px;
            height: 195px;
            margin: 2rem auto;
            .achievement-bg{
                line-height: 159px;
                font-size: 159px;
                top:10px;
            }
            .achievement-value{
                font-size:59px;
            }
            .achievement-label{
                font-size:25px;
            }
            .achievement-icon{
                width:60px;
            }
        }
    }
}

.bg-light{
    .achievement-wrap{
        color:$dark;
    }
}