.cc-window {
  font-family: $font-family-sans-serif!important;
  background-color: $dark !important;
  font-size: 0.875rem !important;
  box-shadow: inset 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
}

.cc-btn {
  border-radius: $border-radius !important;
  color: $primary !important;
  font-weight: 500 !important;
  background-color:$secondary!important;
  text-decoration: none;
  font-family: $headings-font-family;

  &:hover {
    background-color: #ececec !important;
  }
}
.cc-revoke {
  display: none !important;
}
