.page-header {
    display:flex;
    align-items: center;
    justify-content: left;
    .back-link{
        color:$white;
        background-color: $secondary-accent;
        border-radius: 50%;
        width:30px;
        height:30px;
        min-width: 30px;
        min-height: 30px;
        display:inline-block;
        line-height: 30px;
        text-align: center;
        font-size:1rem;
        margin-right:.6rem;

      transition: all 150ms ease-in-out;
    }
  .app-icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right:.5rem;

      transition: all 150ms ease-in-out;
    svg {
      position: relative;
      top: -4px;
      fill: $primary;
      width: 100%;
      height: auto;
    }
  }
  h1 {
    text-transform: uppercase;
    font-size:1.125rem;
    color:$primary;
    margin:0;
  }
}

.bg-primary{
  .page-header h1, .page-header .h1{
    color:$white;
  }
}


.page-header {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
}
.page-header-spacer{
  height:50px;
}


@include media-breakpoint-down(md) {
.page-header-sticky{
  position:fixed !important;
  top:0;
  left:0;
  background-color: $white;
  padding:1rem;

  border-radius: 0 0 $border-radius-lg $border-radius-lg;
  color: $dark;
  box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.1);
  margin: 0;
  z-index:999!important;

  .back-link{
    width:24px;
    height: 24px; 
    min-width: 24px;
    min-height: 24px;
    display:inline-block;
    line-height: 24px;
    text-align: center;
    font-size:.875rem;
    margin-right:.4rem;
  }
  .app-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right:.5rem;
    svg {
      position: relative;
      top: -2px;
      fill: $primary;
      width: 100%;
      height: auto;
    }
  }
  h1 {
    text-transform: uppercase;
    font-size:1rem;
    color:$primary !important;
    margin:0;
  }
}
}