@charset "UTF-8";
// FA
@import "../node_modules/@fortawesome/fontawesome-svg-core/styles.css";

// bootstrap
@import "node_modules/bootstrap/scss/functions";
@import "styles/bootstrap/bootstrap-variables";
@import "node_modules/bootstrap/scss/bootstrap";

@import 'swiper/scss';
@import '~swiper/scss/navigation';
@import '~swiper/scss/pagination';
//inline vars
$sidebar-width : 340px;

// UI
@import "styles/_accessibility";
@import "styles/_general";
@import "styles/_buttons";
@import "styles/forms";
@import "styles/_navbar";
@import "styles/_footer-nav";
@import "styles/_sidebar";
@import "styles/_footer";
@import "styles/_animations";
@import "styles/aside-menu";
@import "styles/add-to-homescreen";
@import "styles/older-browsers";
@import "styles/cookie-consent";
@import "styles/loading-spinner";
@import "styles/tracker-list";
@import "styles/header-bar";
@import "styles/media-queries";
@import "styles/helpers";
@import "styles/_task-list";
@import "styles/_tabs";
@import "styles/_page-header";
@import "styles/_login";
@import "styles/_symptoms";
@import "styles/_date-picker";
@import "styles/_achievement-notification";


/* Importing Datepicker SCSS file. */
@import "~ngx-bootstrap/datepicker/bs-datepicker";
