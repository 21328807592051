@use 'sass:math';

.top-0 {
    top: 0;
  }
  .mt-0 {
    margin-top: 0;
  }
  .pt-0 {
    padding-top: 0;
  }
  .stick-bottom{
      position: fixed;
      bottom:0;
      left:0;
      width:100%;
      padding:1rem;
  }
  .stick-bottom.stick-bottom-with-nav{
      bottom:60px;
  }
.expand{
  margin-right:math.div($grid-gutter-width, 2) * -1; 
  margin-left:math.div($grid-gutter-width, 2) * -1;
  padding-left:math.div($grid-gutter-width, 2);
  padding-right:math.div($grid-gutter-width, 2);
}

  .mb-6{
    margin-bottom:6rem;
  }
  .mt-n4{
    margin-top:-4rem;
  }
  .mt-n5{
    margin-top:-5rem;
  }
  .mt-n6{
    margin-top:-6rem;
  }
  .mt-n7{
    margin-top:-7rem;
  }
  .mt-n8{
    margin-top:-7rem;
  }
  .relative{
    position: relative;
  }

  // media queries
@include media-breakpoint-up(md) {
  .pd-md-1{
    padding-top:1rem;
} .pd-md-2{
  padding-top:2rem;
}
  .pd-md-3{
      padding-top:3rem;
  }
}
  
.rounded-lg{
  border-radius: $border-radius-lg;
}