.nav-tabs .nav-link {
  border: none;
  background: none !important;
  border-bottom: 2px solid #ccc;
  font-size: 0.875rem;
  font-family: $headings-font-family;
  padding: 0.5rem;
  color: $gray-666;
  .badge {
    border-radius: 50%;
    width: 17px;
    height: 17px;
    font-family: $font-family-sans-serif;
    font-size: 0.625rem;
    line-height: 17px;
    text-align: center;
    padding: 0;
    margin-left: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    color: $gray-666;
  }
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-color: $primary;
  color: $primary;
  .badge {
    color: $white;
    background-color: $primary;
  }
}

.bg-primary {
  .nav-tabs .nav-link {
    color: rgba(255, 255, 255, 0.75);
    border-color: 2px solid rgba(255, 255, 255, 0.5);
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    border-color: $white;
    color: $white;
    .badge {
      color: $white;
      background-color: $primary;
    }
  }
}
