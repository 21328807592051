html {
  position: relative;
  min-height: 100%;
  scroll-behavior: smooth;
}
.container-main {
  // background-color: $primary;
}
.fh {
  min-height: 100vh;
}
.fh-nav {
  min-height: calc(100vh - 60px);
}
.fh-nav-subnav {
  min-height: calc(100vh - 151px);
}
.fh-nav-subnav-stagenav {
  min-height: calc(100vh - 197px);
}
.content-container {
  padding: 20px;
  .row {
    margin-top: 1rem;
  }
  p {
    font-size: 0.875rem;
  }
}
.container-narrow {
  max-width: 780px;
  margin: 0 auto;
}
.bg-dark {
  background: $dark;
  color: $white;
  a {
    color: $white;
  }
  hr {
    border-color: rgba($white, 0.5);
  }
}
.bg-grey {
  background-color: #ddd;
}
.bg-menu-bg {
  background-color: $dark;
}
.text-menu-bg {
  color: $dark;
}
.text-light-grey {
  color: #ddd;
}
.divider {
  border-top-width: 1px;
}
.divider-light {
  border-color: rgba(255, 255, 255, 0.5);
}
.drop-shadow {
  box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.1);
}

a .drop-shadow:active {
  box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.2);
  transform: translate(3px, 3px);
}

.pointer {
  cursor: pointer;
}
// extra bgs
@each $color, $value in $colors {
  .bg-#{$color} {
    background-color: rgba($value, 1) !important;
  }
}
@each $color, $value in $theme-colors {
  .bg-#{$color}-dark {
    background-color: rgba(darken($value, 14), 1);
  }
}
@each $color, $value in $theme-colors {
  .bg-#{$color}-darker {
    background-color: rgba(darken($value, 24), 1);
  }
}

// form addons
.inner-addon {
  position: relative;
}
.inner-addon .addon {
  position: absolute;
  padding: 10px;
  z-index: 20;
}
.inner-addon .addon.btn {
  padding: 5px;
  margin: 5px;
  text-align: center;
}
.left-addon .addon {
  left: 0px;
}
.right-addon .addon {
  right: 0px;
}
.left-addon input {
  padding-left: 30px;
}
.right-addon input {
  padding-right: 30px;
}

a.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.nmh {
  min-height: auto !important;
}
.icon-xl {
  font-size: 3rem;
}

.icon-button {
  margin-top: -0.2rem;
  padding: 0.2rem 0.5rem;
  display: inline-block;
  border-radius: $border-radius;
  transition: 0.3s;
  &:hover {
    background-color: rgb(255, 255, 255, 0.1);
  }
  &:active {
    background-color: rgb(255, 255, 255, 0.2);
  }
}

.cc-window {
  background-color: $dark !important;
  font-size: 0.875rem !important;
  font-family: "Frutiger" !important;
  box-shadow: inset 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
}

.cc-btn {
  border-radius: $border-radius !important;
  color: $primary !important;
  font-weight: 300 !important;

  &:hover {
    background-color: #ececec !important;
  }
}
.cc-revoke {
  display: none !important;
}
// media queries
@include media-breakpoint-up(md) {
  .content-wrap {
    margin: 1rem auto;
    background-color: $white;
    border-radius: $border-radius;
    min-height: 100vh;
    padding: 0.5rem 1.5rem;
  }
  .content-wrap-np {
    padding: 0;
  }
  router-outlet ~ * {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

// content box
.content-container {
  padding: 1rem;
  padding-bottom: 100px;
}

.card.bg-primary {
  color: $white;
}
.content-box {
  position: relative;
  z-index: 1;
  border-radius: $border-radius-lg $border-radius-lg 0 0;
  background-color: rgba($primary, 0.9);

  padding: 1rem;

  margin-top: 15rem;
  color: $white;
  h1 {
    margin-bottom: 2rem;
    font-size:1.5rem;
    small {
      font-size: 0.625rem;
      font-family: $font-family-sans-serif;
      text-transform: none;
      line-height: 2rem;
      font-weight: 500;
      color: $primary-accent;
    }
  }
}

.inline-title {
  text-align: left;
  text-transform: uppercase;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  .muted {
    font-size: 0.625rem;
    line-height: 1.2rem;
  }
}
.card {
  color: $dark;
  border-radius: $border-radius-lg;
  .card-title {
    text-align: center;
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    .icon-circle {
      background-color: $white;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      position: relative;
      top: -5px;
    }
  }

  .card-title-sm{
    font-size:0.625rem;
    font-weight: bold;
  }
  .card-footer {
    border-radius: 0 0 $border-radius-lg $border-radius-lg;
    a {
      text-decoration: none;
      color: $primary;
      font-weight: 700;
      font-family: $headings-font-family;
      text-align: center;
      display: block;
      font-size: 0.875rem;
    }
  }

  .divider{
    background-color:$white;
    height:2px;
    opacity:1;
   }
   .card-text-sm{
    font-size:0.625rem;
   }
}
.card.bg-white p{
  color:#666;
}
.card.widget {
  .card-body {
    padding: 1rem 0.5rem;
  }
}
.card-body {
  p {
    font-size: 0.875rem;
  }
  p.subtext {
    color: $gray-666;
    font-size: 0.625rem;
  }
}
.img-sticky {
  position: fixed;
  z-index: 0;
  width: 100%;
  top: 0;
  left: 0;
}

h1 {
  text-transform: uppercase;
  .icon-wrap {
    width: 20px;
    height: 20px;
    display: inline-block;
    svg {
      position: relative;
      top: -4px;
      fill: $secondary-accent;
      width: 100%;
      height: auto;
    }
  }
}

.widget-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-items: stretch;
  .icon-wrap {
    width: 64px;
    max-height: 64px;
    display: inline-block;
    flex: 1;
    svg {
      fill: $primary;
      width: auto;
      height: 64px;
    }
  }

  .widget-status {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: stretch;
    .widget-label {
      border-radius: 10px;
      background-color: $white;
      margin: 2px 0px 2px 10px;
      flex: 1;
      padding: 2px 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      align-items: stretch;
      span {
        margin: 0 5px;
      }
      .not-hit {
        font-size: 2rem;
        line-height: 1rem;
      }
      .not-set {
        font-size: 0.625rem;
        line-height: 0.625rem;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
}

// media queries{

@include media-breakpoint-up(lg) {
  .content-box {
    margin-top: 2rem;
  }
}

// entries

.entry-days {
  display: flex;
  justify-content: center;
  align-items: center;
  .entry-day {
    text-align: center;
    margin: 2px;
    .entry-day-label {
      font-size: 0.875rem;
      font-weight: 700;
    }
    .entry-day-data {
      border-radius: 0.5rem;
      width: 38px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: $white;
      color: $primary;
      .entry-day-data-value {
        font-size: 1.0625rem;
        font-weight: 700;
        line-height: 1rem;
      }
      .entry-day-data-unit {
        font-size: 0.6875rem;
      }
    }
    .entry-day-data.complete {
      background-color: $primary;
      color: $white;
    }
    .entry-day-data.na {
      background-color: rgba($white, 0.5);
      color: $primary;
    }
  }
}

.goal-status-row {
  margin: 0;
  .col {
    padding: 0.5rem;
  }
  .goal-status-value {
    font-size: 1.5rem;
    color: $primary;
    font-family: $headings-font-family;
    font-weight: 800;
  }
  .col:first-child {
    border-top: 2px solid #eee;
    border-right: 1px solid #eee;
  }
  .col:last-child {
    border-top: 2px solid #eee;
    border-left: 1px solid #eee;
  }
}
// end entries

// achievements
.achievements {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
  clear: both;
  flex-wrap: wrap;
}
.achievements {
  .achievement {
    margin-bottom: 1rem;
  }
}
.achievement {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $white;
  border-radius: 10px;
  width: 60px;
  height: 60px;
  position: relative;
  margin: 0 0.5rem;
  .achievement-bg {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 60px;
    width: 100%;
    height: 100%;
    z-index: 0;
    color: #eee;
    text-align: center;
    font-size: 48px;
  }
  .achievement-content {
    text-align: center;
    position: relative;
    z-index: 1;
    font-size: 0.5rem;
    font-family: $headings-font-family;
    color: $primary;
  }
  .achievement-value {
    font-size: 1.125rem;
    font-weight: 800;
    line-height: 1.125rem;
  }
  .achievement-label {
    text-transform: uppercase;
    white-space: pre;
    span{
      line-height: 1;
    }
  }
  .achievement-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .achievement-icon {
    width: 20px;
    display: inline-block;
    svg {
      width: 100%;
      height: auto;
      fill: $secondary-accent;
    }
  }
}
.achievement-primary {
  background-color: $primary;
  color: $white;
  .achievement-content {
    color: $white;
  }
  .achievement-icon {
    svg {
      fill: $white;
      fill: $white;
    }
  }
}
// end achievements

// media

@include media-breakpoint-down(md) {
  /*.achievements {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }*/
}

.goal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0.5rem;
  .goal-checkbox {
    min-width: 30px;
    width: 30px;
    height: 30px;
    background-color: $white;
    border-radius: 10px;
    display: inline-block;
    text-align: center;
    line-height: 30px;
    margin-right: 10px;
    color: #efefef;
  }
}
.target-status {
  align-items: center;
  margin-bottom: 1rem;
}
.target-status-status {
  font-size: 0.875rem;
  .icon-wrap {
    font-size: 1rem;
  }
  color: $primary;
}
.target-status-date {
  font-size: 0.625rem;
}

.status-calendar {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  margin: 1rem 0;
  .status-calendar-header {
    font-family: $headings-font-family;
    font-weight: 700;
    font-size: 0.875rem;
  }
  .status-calendar-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 2px 2px;
    gap: 2px 2px;
    margin-bottom: 2px;
    text-align: center;
    .status-calendar-day {
      display: inline-block;
      width: 21px;
      height: 21px;
      border-radius: 50%;
      font-size: 0.625rem;
      text-align: center;
      line-height: 21px;
    }

    .status-calendar-day.incomplete {
      background-color: $white;
    }
    .status-calendar-day.complete {
      background-color: $primary;
      color: $white;
    }
  }
}

.status-badge {
  background-color: $white;
  border-radius: 10px;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .status-badge-icon {
    color: $secondary-accent;
    font-size: 1.5rem;
    margin-right: 10px;
  }
  .status-badge-text {
    text-align: center;
    .status-badge-title {
      color: $gray-666;
      font-size: 0.625rem;
    }
    .status-badge-status {
      font-size: 1.063rem;
      font-weight: 700;
      color: $primary;
      span {
        font-size: 0.6875rem;
        font-weight: 400;
      }
    }
  }
}

// swipetr test
.swiper {
  width: 100%;
  padding-bottom: 30px;
}
.swiper-slide {
  text-align: left;
  font-size: 0.875rem;
}

.swiper-pagination-bullet {
  background-color: $secondary-accent;
  opacity: 1;
  width: 10px;
  height: 10px;
}
.swiper-pagination-bullet-active {
  background-color: $primary;
}
.swiper-pagination {
  bottom: 0px !important;
}

// symptom-status
.symptom-status {
  margin-bottom: 10px;
  .symptom-status-label {
    font-size: 0.625rem;
    font-family: $headings-font-family;
    font-weight: 800;
    color: $primary;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1px;
  }
  .symptom-status-box {
    background-color: $white;
    border-radius: 10px;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .symptom-status-icon {
      font-size: 1rem;
      margin-right: 2px;
    }
    .symptom-status-value {
      font-size: 1.5rem;
      font-family: $headings-font-family;
      font-weight: 800;
      margin-left: 2px;
    }
    .icon-selection {
      border-radius: 5px;
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      color: $white;
    }
  }
}
.symptom-status-inline {
  display: inline-block;
  .symptom-status-icon {
    margin-right: 5px;
  }
  .symptom-status-icon {
    margin-right: 5px;
  }
}



h3.sm-title, h2.sm-title {
  font-size: 1.5rem;
}