.sidebar {
  display: none;
}
// media queries
@include media-breakpoint-up(lg) {
  .sidebar-bg {
    display: block;
    position: fixed;
    z-index: 1;
    min-height: 100vh;
    height: 100%;
    width: $sidebar-width;
    margin: -1rem 0;
    background-color: $white;
  }

  .sidebar {
    display: block;
    position: absolute;
    z-index: 3;
    min-height: 100vh;
    height: 100%;
    width: $sidebar-width;
    margin: -1rem 0;
    background-color: $white;
    .sidebar-content {
      padding: 1rem 1.5rem;
    }
    .logo {
      padding: 1rem;
      text-align: center;
      background-color: $primary;
      border-radius: $border-radius;
      img {
        width: 100%;
      }
    }
    .nhs-logo {
      height: 20px;
      width: 50px;
      margin-top: 0.5rem;
    }
  }
  .sidebar-container {
    width: $sidebar-width;
    position: relative;
    top: 0;
    left: 0;
  }
  .sidebar-container-off {
    width: 0;
  }
  .has-sidebar {
    margin-left: $sidebar-width + 20px;
    position: relative;
  }
}
.avatar-icon {
  font-size: 1.125rem;
  background-color: #ddd;
  border-radius: $border-radius;
  color: $dark;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-right: 0.5rem;
}
.avatar-icon.icon-wrap {
  top: auto !important;
}
.sidebar-nav {
  margin-top: 1rem;
  .nav-icon {
    font-size: 1.125rem;
    background-color: #ddd;
    border-radius: $border-radius;
    color: $dark;
    display: inline-block;
    flex: 0 0 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin-right: 0.5rem;
    transition: all 250ms ease-in-out;
    .app-icon{
      svg{
      width: 75%;
      height: auto;
      transition: all 250ms ease-in-out;
      position: relative;
      top:-2px;
      }
    }
    .icon-home{
      svg{
        width: 65%;
      }
    }
    .icon-yoga{
      svg{
        width: 65%;
      }
    }
    .icon-strength{
      svg{
        width: 65%;
      }
    }
    .icon-symptoms{
      svg{
        width: 65%;
      }
    }
  }

  .nav-link {
    font-size: 1rem;
    padding-left: 0;
    display: flex;
    padding: 0rem;
    margin-bottom: 0.75rem;
    transition: all 250ms ease-in-out;
    &:hover .nav-icon {
      background-color: $dark;
      color: $white;
      .app-icon{
        svg{
        fill:$white;
        }
      }
    }
    &:hover .nav-link-title {
      background-color: #ddd;
    }
  }

  .nav-link-title {
    line-height: 40px;
    height: 40px;
    flex: 1;
    border-radius: $border-radius;
    display: inline-block;
    padding: 0 10px;
    transition: all 250ms ease-in-out;
  }

  .active {
    .nav-icon {
      background-color: $primary;
      color: $white;
      .app-icon{
        svg{
        fill:$white;
        }
      }
    }
    
  }
  ul ul {
    display: none;
    margin: 0rem 0 1.5rem 3rem;
    padding: 0;
    border-top: 1px solid #ddd;
    .nav-item {
      border-bottom: 1px solid #ddd;
      .nav-link {
        font-size: 0.875rem;
        color: $dark;
        padding: 0.6rem 0.5rem;
        margin-bottom: 0rem;
        transition: all 250ms ease-in-out;
        &:hover {
          background-color: #ddd;
        }
        .icon-wrap {
          color: $primary;
          margin-right: 0.5rem;
        }
      }
      .active {
        color: $primary;
      }
    }
  }
  .active ~ ul {
    display: block;
  }
}
.user-menu-group {
  display: block;
  border-bottom: 2px solid #ddd;
  padding-bottom: 1rem;
  .dropdown-menu {
    width: $sidebar-width - 45px;
  }
  > a {
    display: block;
  }
  .user-name {
    margin-left: 1rem;
    font-size: 1rem;
    color: $dark;
  }
  .icon-wrap {
    color: $dark;
    font-size: 1.125rem;
    position: relative;
    top: 5px;
  }
}
.search-form {
  .btn-transparent {
    position: absolute;
    right: 10px;
    top: 12px;
    color: $primary;
    font-size: 1.125rem;
  }
}
