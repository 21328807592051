.aside-menu-wrap {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1029;
  top: 0;
  left: 0;
  background-color: $primary;
}

.aside-menu {
  overflow-y: scroll;
  padding-bottom: 150px;
  height:100vh;

  hr {
    border-color: $white;
  }

  .task-list {
    font-size: 1rem;
    .num {
      background-color: $secondary;
      color: $primary;
    }
    .icon-wrap {
      color: $primary;
    }
    .task {
      padding-left: 1rem;
      color: $white;
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  .task-list-aside-user {
    .task {
      margin-left: 5px;
      border-radius: $border-radius $border-radius 0 0;
    }
  }
  .aside-sub {
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.1);
    color: $white;
    border-radius: 0 0 $border-radius $border-radius;
    margin: -10px 0 0 45px;
    padding-top: 10px;
    a {
      color: $white;
      margin: 2px 0;
    }
    .icon-wrap {
      color: $white;
      margin-right: 1rem;
    }
  }
}

.slide-in {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s ease-in-out;
}

.slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}
