.tracker-list{
    list-style: none;
    margin:0;
    padding:0;
    li{
       
        > a, .flex-wrap{
            display: flex;
            flex-direction: row;
            align-items: center;
            align-content: stretch;
            flex-flow: row nowrap !important;
            justify-content: flex-start;
            border-bottom:1px solid #EBEBEB;
            padding:20px 10px;
            transition: 0.3s;
        }
        >a:active, >a:hover{
            text-decoration: none;
            background-color: #ddd;;
        }
        .tracker-icon{
            font-size: 2.875rem;
            color:#425563;
            margin-right: 1.5rem;
        }
        .tracker-label{
            background-color:$dark;
            color:$white;
            font-size:1.125rem;
            margin-right:1rem;
            padding:8px 5px 5px 5px;
            min-width:40px;
            min-height: 40px;
            text-align: center;
            border-radius: $border-radius;
            line-height:0.7;
            .small{
                font-size:0.75rem;
                line-height:0.75rem;
                margin:0;
            }
        }
        .tracker-label-lg{
            font-size:1.5rem;
            margin-right:1rem;
        }
        .tracker-info{
            flex-grow:1;
            .tracker-link{
                display:block;
                font-size: 1rem;
                color:$primary;

                > .icon-symptom {
                    color: $dark;
                    margin-right: 0.5rem;
                }
                > .icon-wrap{
                    color: $primary;
                }
                
            }
            .details{
                display:block;
                color:#425563;
            }
            .details-sm{
                display:block;
                font-size:0.75rem;
                color:$dark;
            }
            .progress{
                margin:5px 40px 4px 0;
            }
            .small{
                color:$dark;
            }
        }
        .tracker-info-lg{
            font-size:1rem;
        }
    }
    li:first-child{
        border-top:1px solid #EBEBEB;
    }
}
.bg-bmi{
    background: $white;
    background: linear-gradient(90deg, $yellow 13%, $green 18%, $green 50%, $orange 62%, $orange 83%, $red 88%);
    position: relative;
    .dot{
        background-color:$white;
        width:9px;
        height:9px;
        position: absolute;
        border-radius: 50%;
    }
}

.tracker-list-no-bt{
    li:first-child{
        border-top:none;
    }
}
.tracker-list-symptoms{
    li{
        border-bottom:1px solid #EBEBEB;
        > a, .flex-wrap{
            border-bottom:none;
        }
        .symptom-feedback{
            padding-left:0rem;
            padding-right:2.5rem;
            margin-top: 1rem;
        }
    }
}