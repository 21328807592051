.btn {
  font-family: $headings-font-family;
  font-weight: 700;
  .icon-wrap{
    margin-right:5px;
  }
}

.card-body{
  .btn{
    padding:0.25rem 1rem;
  }
}

.btn-icon-only{
  .icon-wrap{
    margin-right:0;
  }
}
.btn-outline-primary {
  border-width: 2px;
}

.btn-lg{
  padding:0.5rem 1rem;
}
@each $color, $value in $theme-colors {
  .btn-transparent-#{$color} {
    @include button-variant(rgba($value, 0.8), rgba($value, 0.8));
  }
}
.btn-primary{
  color: $white;
}
.btn-wide{
  padding-left:2rem !important;
  padding-right:2rem !important;
}
.card-primary{
  .btn-white{
    color:$primary;
  }
}

.btn-secondary{
  color:$primary;
}